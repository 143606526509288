<template>
  <div>
    <b-form @submit.prevent="submitCreate" enctype="multipart/form-data">
      <div class="form-row">
        <div class="col-md-8">
          <b-card class="mb-1" header="Informações básicas">
            <b-card-text>
              <div class="form-row">
                <b-col md="8">
                  <b-form-group class="mb-0">
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Título
                    </label>
                    <b-form-input
                      v-model="item.title"
                      :class="{ 'is-invalid': $v.item.title.$error }"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group class="mb-0">
                    <label for="">
                      #ID - MIGRAR
                      <feather-icon
                        size="16"
                        class="text-info"
                        icon="HelpCircleIcon"
                        v-b-tooltip.hover.v-dark
                        title="Caso esteja migrando o produto da versão anterior da Seducar, insira o ID do produto, localizado na tabela de Produtos Digitais > Curso online."
                      />
                    </label>
                    <b-form-input type="number" v-model="item.id_old" />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    class="mt-1"
                    :class="{ 'is-invalid': $v.item.description.$error }"
                  >
                    <label for="">
                      <i class="text-danger bi bi-record-circle"></i>
                      Descrição
                    </label>
                    <editor
                      class="about-editor-1"
                      api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                      v-model="item.description"
                      :init="
                        this.optionEditor({
                          height: 600,
                          selector: 'about-editor-1',
                        })
                      "
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group class="mt-1 mb-0">
                    <label for="">
                      Sobre o produto -
                      <small>Aparecerá no final da página.</small>
                    </label>
                    <editor
                      class="about-edito-2"
                      api-key="22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp"
                      v-model="item.about"
                      :init="
                        this.optionEditor({
                          height: 300,
                          selector: 'about-edito-2',
                        })
                      "
                    />
                  </b-form-group>
                </b-col>
              </div>
            </b-card-text>
          </b-card>

          <b-card header="Sales Force" header-class="mb-1" class="mb-1">
            <b-card-text>
              <div class="form-group mb-2">
                <b-form-checkbox v-model="item.sales_force_positions"
                  class="custom-control-primary">
                  Exibir cargos (Sales Force)
                </b-form-checkbox>
              </div>

              <div class="form-group mb-0" v-if="item.sales_force_positions">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Cargos
                </label>
                <v-select class="v-select-tags" label="title" item-text="title" item-value="code"
                  v-model="selectPosition" placeholder="Digite o título" :options="optionsSalesForcePositions"
                  @search="fetchSalesForcePositions" @input="positionSelected">
                  <span slot="no-options">
                    Nenhum registro encontrado
                  </span>
                </v-select>

                <draggable v-if="item.positions" tag="ul" :list="item.positions" class="list-group mt-1"
                  handle=".handle">
                  <li v-for="(element, idx) in item.positions" :key="element.code" class="list-group-item">
                    <feather-icon icon="MoveIcon" class="handle" size="15" />

                    <span class="pl-1 text-not-selected">{{ element.title }}</span>

                    <feather-icon icon="XCircleIcon" class="float-right cursor-pointer text-danger" size="20" @click="removeAtPosition(idx)" />
                  </li>
                </draggable>

                <p class="mt-1 mb-0">Os cargos serão listados no formulário de interesse, na página do produto.</p>
              </div>

              <div v-else class="form-group mb-0">
                <label for="">
                  <i class="text-danger bi bi-record-circle"></i>
                  Sales force
                </label>
                <v-select ref="selectSalesForce" class="text-elipse-300" label="title" item-text="title" item-value="code"
                  v-model="item.salesforce" placeholder="Digite o título" :options="optionsSalesForce"
                  @search="fetchSalesForce" :class="{ 'is-invalid': $v.item.salesforce.$error }">
                  <span slot="no-options" @click="$refs.selectSalesForce.open = false">
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </div>

            </b-card-text>
          </b-card>

          <div
            class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1"
          >
            <div class="card-header mb-2">
              <div class="form-row">
                <div class="col-md-10 col-8">
                  <h4 class="mb-0">
                    Turmas
                    <small class="d-block mt-50"
                      >Vincule as turmas relacionadas a este curso presencial</small
                    >
                  </h4>
                </div>
                <div class="col-md-2 col-4">
                  <button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="$bvModal.show('modal-classroom')"
                    class="btn btn-primary btn-block"
                    type="button"
                  >
                    <i class="bi bi-plus mr-25"></i>
                    <span>Novo</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <draggable
                tag="ul"
                :list="item.classrooms"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, index) in item.classrooms"
                  :key="index"
                  class="list-group-item"
                >
                  <div class="flex-list-items-dragable">
                    <feather-icon
                      icon="MoveIcon"
                      class="handle cursor-pointer"
                      size="15"
                    />

                    <span class="pl-1 text-not-selected"
                      >{{ element.title }}
                    </span>

                    <feather-icon
                      icon="XCircleIcon"
                      class="float-right cursor-pointer text-danger"
                      size="20"
                      @click="removeAtClassroom(index)"
                    />
                  </div>
                </li>
              </draggable>

              <p class="mb-0" v-if="!item.classrooms.length">
                Nenhuma turma foi selecionada até o momento.
              </p>
            </div>
          </div>

          <div
            class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1"
          >
            <div class="card-header mb-2">
              <div class="form-row">
                <div class="col-md-10 col-8">
                  <h4 class="mb-0">
                    Disciplinas
                    <small class="d-block mt-50"
                      >Serão somente exibidas no site e não na sala virtual.</small
                    >
                  </h4>
                </div>
                <div class="col-md-2 col-4">
                  <button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgain"
                    class="btn btn-primary btn-block"
                    type="button"
                  >
                    <i class="bi bi-plus mr-25"></i>
                    <span>Novo</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div
                ref="listDisciplines"
                class="repeater-form"
                @submit.prevent="repeateAgain"
              >
                <div
                  class="form-row"
                  v-for="(item, index) in item.disciplines"
                  :id="item.id"
                  :key="item.id"
                  ref="row"
                >
                  <b-col md="8">
                    <div class="form-group">
                      <b-form-input
                        id="title"
                        type="text"
                        placeholder="Título da disciplina"
                        v-model="item.title"
                      />
                    </div>
                  </b-col>

                  <b-col md="2">
                    <div class="form-group">
                      <b-form-input
                        id="time"
                        type="number"
                        placeholder="hora(s)"
                        v-model="item.time"
                      />
                    </div>
                  </b-col>

                  <b-col md="2">
                    <div class="form-group">
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0"
                        block
                        @click="removeItem(index)"
                      >
                        <feather-icon icon="Trash2Icon" class="mr-25" />
                      </b-button>
                    </div>
                  </b-col>

                  <b-col md="12">
                    <div class="form-group">
                      <textarea
                        placeholder="Descrição"
                        class="form-control"
                        v-model="item.description"
                        rows="4"
                      ></textarea>
                    </div>
                  </b-col>
                </div>

                <p class="mb-0" v-if="!item.disciplines.length">
                  Nenhuma disciplina cadastrada até o momento.
                </p>
              </div>
            </div>
          </div>

          <b-card class="mb-1" header="Sites de buscas">
            <b-card-text>
              <p class="mb-2">
                Caso queira personalizar o título, descrição e url para
                mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar
                SEO do site" e preencha os campos abaixo.
              </p>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Título SEO</label>
                    <input
                      v-model="item.title_seo"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="">URL</label>
                    <b-input-group
                      :prepend="`/cursos/presenciais/`"
                      class="input-group-merge"
                    >
                      <b-form-input
                        class="text-primary pl-1"
                        v-model="item.slug"
                      />
                    </b-input-group>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Descrição para SEO</label>
                    <textarea
                      v-model="item.description_seo"
                      maxlength="320"
                      class="form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-md-4">
          <b-card header="Imagem de destaque" class="mb-1">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <input
                    id="image"
                    type="file"
                    accept="image/*"
                    class="form-control-file hidden"
                    @change="previewImage"
                  />
                  <b-form-group>
                    <label
                      for="image"
                      class="d-block text-center"
                      style="cursor: pointer; font-size: 1.1rem"
                    >
                      <div v-if="!preview && !image_url" class="pt-5 py-4">
                        <feather-icon size="19" icon="ImageIcon" />
                        Selecionar imagem
                      </div>
                      <template v-if="preview">
                        <b-img
                          for="image"
                          :src="preview"
                          style="width: 100%; height: 200px"
                          fluid
                        />
                      </template>
                      <template v-else-if="!preview && image_url">
                        <b-img
                          for="image"
                          :src="image_url"
                          style="width: 100%; height: 200px"
                          fluid
                        />
                      </template>
                    </label>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Vídeo de apresentação</label>
                    <b-form-input
                      type="text"
                      placeholder="Link do YouTube/Vimeo"
                      v-model="item.video_url"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>
                    Label da imagem
                    <feather-icon
                      size="9"
                      class="text-danger"
                      icon="DiscIcon"
                    />
                  </label>
                  <b-form-group class="mb-0">
                    <b-form-input
                      :class="{ 'is-invalid': $v.item.label_image.$error }"
                      v-model="item.label_image"
                      type="text"
                    />
                  </b-form-group>
                  <small class="d-block" style="margin-top: 4px">
                    Será exibido sobre a imagem de destaque.
                  </small>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Ficha Técnica">
            <b-card-text>
              <v-select
                ref="selectDatasheet"
                label="title"
                item-text="title"
                item-value="code"
                v-model="item.datasheet"
                :class="{ 'is-invalid': $v.item.datasheet.$error }"
                placeholder="Digite o título"
                :options="optionsDatasheets"
                @search="fetchDatasheets"
              >
                <span
                  slot="no-options"
                  @click="$refs.selectDatasheet.open = false"
                >
                  Nenhum registro encontrado
                </span>
              </v-select>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Detalhes">
            <b-card-text>
              <div class="row">
                <b-col md="12">
                  <div class="form-group">
                    <label for="">
                      Tempo de acesso <small>(dias) </small>
                      <feather-icon
                        size="9"
                        class="text-danger"
                        icon="DiscIcon"
                      />
                    </label>
                    <input
                      v-mask="['####']"
                      type="tel"
                      min="1"
                      v-model="item.validity"
                      :class="{ 'is-invalid': $v.item.validity.$error }"
                      placeholder="365"
                      class="form-control"
                    />
                  </div>
                </b-col>
                <div class="col-md-12 col-12">
                  <label for="">
                    Subcategoria
                    <feather-icon
                      size="9"
                      class="text-danger"
                      icon="DiscIcon"
                    />
                  </label>
                  <b-form-group>
                    <v-select
                      ref="selectSub"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.subcategory"
                      placeholder="Digite o título"
                      :options="optionsSubcategories"
                      @search="fetchSubcategories"
                      :class="{ 'is-invalid': $v.item.subcategory.$error }"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectSub.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>
                <div class="col-md-12 col-12">
                  <label for="">
                    Área(s)
                    <feather-icon
                      size="9"
                      class="text-danger"
                      icon="DiscIcon"
                    />
                  </label>
                  <b-form-group>
                    <v-select
                      multiple
                      ref="selectAreas"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.areas"
                      placeholder="Digite o título"
                      :options="optionsAreas"
                      @search="fetchAreas"
                      :class="{ 'is-invalid': $v.item.areas.$error }"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectAreas.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </b-form-group>
                </div>
              </div>
            </b-card-text>
          </b-card>
          <b-card class="mb-1" header="Preço">
            <b-card-text>
              <b-row class="form-row">
                <b-col md="12">
                  <b-form-group>
                    <label for="">
                      Preço
                      <i class="text-danger bi bi-record-circle"></i>
                    </label>
                    <money
                      class="form-control"
                      v-model="item.price"
                      placeholder="0,00"
                      v-bind="money"
                      :class="{ 'is-invalid': $v.item.price.$error }"
                    ></money>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label for=""> Promocional </label>
                    <money
                      class="form-control"
                      v-model="item.sale"
                      placeholder="0,00"
                      v-bind="money"
                    ></money>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group class="mb-0">
                    <label for=""> Promoção válida até </label>
                    <flat-pickr
                      v-model="item.promotion_date"
                      class="form-control"
                      :config="{
                        dateFormat: 'd/m/Y',
                        altFormat: 'Y-m-d',
                        locale: 'pt',
                      }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Opcionais">
            <b-card-text>
              <b-row class="form-row">
                <div class="col-12 mb-1">
                  <div class="form-group">
                    <label for=""
                      >Depoimento(s) <small>(Aprovados)</small>
                    </label>
                    <v-select
                      multiple
                      ref="selectApproved"
                      label="title"
                      item-text="title"
                      item-value="code"
                      v-model="item.approveds"
                      placeholder="Digite o título"
                      :options="optionsApproveds"
                      @search="fetchApproveds"
                    >
                      <span
                        slot="no-options"
                        @click="$refs.selectApproved.open = false"
                      >
                        Nenhum registro encontrado
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="col-12">
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_store_discount"
                      value="1"
                      class="custom-control-primary"
                    >
                      Não aplicar desconto em loja
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_billet"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desabilitar venda no boleto
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_popup"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar Pop-up de interessado
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_form_contact"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar formulário de contato
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group>
                    <b-form-checkbox
                      v-model="item.disable_whatsapp"
                      value="1"
                      class="custom-control-primary"
                    >
                      Desativar botão do WhatsApp
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group class="mb-0">
                    <b-form-checkbox
                      v-model="item.plataforma_questoes"
                      value="1"
                      class="custom-control-primary"
                    >
                      Acesso a Plataf. de Questões
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </b-row>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Tags">
            <b-card-text>
              <b-row>
                <b-col md="12">
                  <b-form-group class="mb-0">
                    <b-form-tags
                      separator=",;"
                      no-add-on-enter
                      class="form-control"
                      placeholder=""
                      v-model="item.tags"
                      :class="{ 'is-invalid': $v.item.tags.$error }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <p class="mb-0 mt-2">Adicione tags separando-as por vírgula.</p>
            </b-card-text>
          </b-card>

          <b-card class="mb-1" header="Status">
            <b-card-text>
              <b-form-group class="mb-0">
                <v-select
                  label="title"
                  v-model="item.status"
                  :class="{ 'is-invalid': $v.item.status.$error }"
                  :options="[
                    { title: 'Ativo', code: 1 },
                    { title: 'Inativo', code: 0 },
                  ]"
                />
              </b-form-group>
            </b-card-text>
          </b-card>
          <ButtonsActionsFooter
            variant="success"
            :submited="submited"
            text="Salvar"
            subtext="Aguarde..."
          />
          <p class="mt-2">
            <i class="bi bi-plug"></i> ID Vindi: {{ item.external_id }}
          </p>
        </div>
      </div>
    </b-form>

    <b-modal
      id="modal-classroom"
      centered
      title="Vincular Turma(s)"
      cancel-title="Fechar"
      ok-title="Vincular"
      ok-only
    >
      <b-form>
        <b-card-text>
          <b-row class="form-row">
            <b-col md="12">
              <b-form-group>
                <label> Digite o código da turma </label>
                <v-select
                  label="title"
                  item-text="title"
                  item-value="code"
                  v-model="classroom"
                  placeholder="Digite o código"
                  :options="optionsClassrooms"
                  @search="fetchClassrooms"
                  @input="classroomSelected"
                >
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </b-form-group>
            </b-col>

            <div v-if="item.classrooms" class="col-12" style="min-height: 250px">
              <p>Turmas selecionados:</p>
              <draggable
                tag="ul"
                :list="item.classrooms"
                class="list-group mt-1"
                handle=".handle"
              >
                <li
                  v-for="(element, index) in item.classrooms"
                  :key="index"
                  class="list-group-item"
                >
                  <feather-icon icon="MoveIcon" class="handle" size="15" />

                  <span class="pl-1 text-not-selected"
                    >{{ element.title }}
                  </span>

                  <feather-icon
                    icon="XCircleIcon"
                    class="float-right"
                    size="20"
                    @click="removeAtClassroom(index)"
                  />
                </li>
              </draggable>
              <p v-if="!item.classrooms">Nenhuma turma selecionada até o momento.</p>
            </div>
          </b-row>
        </b-card-text>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import draggable from "vuedraggable";

import { stringToSlug } from "@/_helpers/_urlHelper";

import { mapState } from "vuex";
import {
  BCollapse,
  BFormTags,
  BFormGroup,
  BForm,
  BRow,
  BImg,
  BCol,
  BCardText,
  BCard,
  VBTooltip,
  VBToggle,
  BAvatar,
  BEmbed,
  BButton,
  BModal,
  BCardFooter,
  BSpinner,
  BListGroup,
  BBadge,
  BListGroupItem,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import { Money } from "v-money";
import Ripple from "vue-ripple-directive";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import vSelect from "vue-select";
import "swiper/css/swiper.css";

import { required, requiredIf } from "vuelidate/lib/validators";

import { heightTransition } from "@core/mixins/ui/transition";

import Editor from "@tinymce/tinymce-vue";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

export default {
  components: {
    BInputGroup,
    editor: Editor,
    flatPickr,
    BCard,
    BCollapse,
    Swiper,
    SwiperSlide,
    BSpinner,
    Money,
    BBadge,
    BFormCheckbox,
    BModal,
    BImg,
    BEmbed,
    BCardText,
    draggable,
    BFormTags,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BCardFooter,
    BListGroup,
    BListGroupItem,
    ButtonsActionsFooter,
  },
  data() {
    return {
      nextTodoId: 2,
      filters: {
        tag_subject: [],
        tag_contest: [],
        tag_timeline: [],
        teacher: [],
        title: "",
      },
      modalShow: false,
      submited: false,
      submitedFind: false,
      preview: null,
      image_url: "",
      user: {},
      filteredOptions: [],     
      wasUploads: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      item: {
        title: "",
        label_image: "",
        description: "",
        about: "",
        validity: 365,
        slug: "",
        price: "",
        sale: "",
        positions: [],
        title_seo: "",
        description_seo: "",
        status: { title: "Ativo", code: 1 },
        areas: [],
        books: [],
        tags: [],
        datasheet: "",
        salesforce: "",
        approveds: "",
        subcategory: [],
        classrooms: [],
        disable_store_discount: false,
        disable_billet: false,
        disable_popup: false,
        disable_form_contact: false,
        disable_whatsapp: false,
        product_category_uuid: "",
        sales_force_positions: false,
        disciplines: [
          {
            id: 1,
            title: "",
            time: "",
          },
        ],
      },
      selectPosition: "",
      optionsDatasheets: [],
      optionsSalesForcePositions: [],
      optionsAreas: [],
      optionsSalesForce: [],
      optionsSubcategories: [],
      optionsApproveds: [],
      optionsClassrooms: [],
      classroom: '',
      uuid: "",
    };
  },
  mounted() {
    this.initTrHeight();
  },
  watch: {
    "item.slug": {
      handler() {
        this.item.slug = stringToSlug(this.item.slug);
      },
    },
  },
  created() {
    this.uuid = this.$route.params.uuid;
    window.addEventListener("resize", this.initTrHeight);
    this.getData();
  },
  computed: {
    ...mapState("Product", ["searchSubcategories", "product"]),
    ...mapState("Area", ["searchAreas"]),
    ...mapState("Approved", ["searchApproveds"]),
  },
  methods: {
    removeAtPosition(idx) {
      this.item.positions.splice(idx, 1);
    },
    positionSelected (e) {
      this.selectPosition = ''
      this.item.positions.push(e)
    },
    async fetchSalesForcePositions(term) {
      if (term.length > 2) {
        this.$store.dispatch("SalesForce/search", term).then((response) => {
          this.optionsSalesForcePositions = response;
        });
      }
    },
    classroomSelected(event) {
      if (event) {
        this.optionsClassrooms = [];
        this.classroom = "";

        if (
          !this.item.classrooms.find((element) => element.code === event.code)
        ) {
          this.item.classrooms.push({
            code: event.code,
            title: event.title,
          });
        }
      }
    },
    removeAtClassroom(idx) {
      this.item.classrooms.splice(idx, 1);
    },
    async fetchClassrooms(term) {
      if (term.length > 1) {
        this.$store.dispatch("Classroom/search", term).then((res) => {
          this.optionsClassrooms = res;
        });
      }
    },
    async getData() {
      this.$store
        .dispatch("Product/show", this.$route.params.uuid)
        .then((data) => {
          this.item = data;
          this.item.disable_store_discount = data.disable_store_discount
            ? 1
            : 0;
          this.item.plataforma_questoes = data.plataforma_questoes ? 1 : 0;
          this.item.disable_billet = data.disable_billet ? 1 : 0;
          this.item.disable_popup = data.disable_popup ? 1 : 0;
          this.item.disable_form_contact = data.disable_form_contact ? 1 : 0;
          this.item.disable_whatsapp = data.disable_whatsapp ? 1 : 0;
          this.image_url = data.image_url;
          this.item.tags = data.tags;
          this.nextTodoId = data.disciplines.length + 1;
          this.item.sales_force_positions = this.item.sales_force_positions ? true : false;
        });
    },
    async fetchApproveds(term) {
      if (term.length > 1) {
        this.$store.dispatch("Approved/search", term).then(() => {
          this.optionsApproveds = this.searchApproveds;
        });
      }
    },
    async fetchSubcategories(term) {
      if (term.length > 2) {
        this.$store
          .dispatch("Product/searchSubcategory", {
            uuid: process.env.VUE_APP_PRODUCT_CATEGORY_PRESENCIAL_UUID,
            term,
          })
          .then(() => {
            this.optionsSubcategories = this.searchSubcategories;
          });
      }
    },
    async fetchAreas(term) {
      if (term.length > 2) {
        this.$store.dispatch("Area/search", term).then(() => {
          this.optionsAreas = this.searchAreas;
        });
      }
    },
    async fetchSalesForce(term) {
      if (term.length > 2) {
        this.$store.dispatch("SalesForce/search", term).then((response) => {
          this.optionsSalesForce = response;
        });
      }
    },
    async fetchDatasheets(term) {
      if (term.length > 2) {
        this.optionsDatasheets = await this.$store.dispatch(
          "Datasheet/search",
          term
        );
      }
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        const file = input.files[0];
        this.item.image = file;
        reader.readAsDataURL(file);
      }
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      const formData = new FormData();
      formData.append("image", this.item.image);
      formData.append("promotion_date", this.item.promotion_date ?? "");
      formData.append("title", this.item.title);
      formData.append("title_seo", this.item.title_seo);
      formData.append("validity", this.item.validity);
      formData.append("price", this.item.price);
      formData.append("sale", this.item.sale);
      formData.append("id_old", this.item.id_old ?? '');
      formData.append("description", this.item.description);
      formData.append("about", this.item.about);
      formData.append("description_seo", this.item.description_seo);
      formData.append("label_image", this.item.label_image);
      formData.append("video_url", this.item.video_url ?? "");
      formData.append('classrooms', this.item.classrooms ? JSON.stringify(this.item.classrooms) : []);
      formData.append("sales_force_positions", this.item.sales_force_positions ? 1 : 0);
      formData.append("positions", this.item.positions ? JSON.stringify(this.item.positions) : '');
      formData.append(
        "datasheet",
        this.item.datasheet ? this.item.datasheet.code : ""
      );
      formData.append(
        "salesforce",
        this.item.salesforce && !this.item.sales_force_positions ? this.item.salesforce.code : ''
      );
      formData.append(
        "approveds",
        this.item.approveds ? JSON.stringify(this.item.approveds) : []
      );
      formData.append(
        "areas",
        this.item.areas ? JSON.stringify(this.item.areas) : []
      );
      formData.append(
        "subcategory",
        this.item.subcategory ? this.item.subcategory.code : ""
      );
      formData.append(
        "disable_store_discount",
        this.item.disable_store_discount ? 1 : 0
      );
      formData.append(
        "plataforma_questoes",
        this.item.plataforma_questoes ? 1 : 0
      );
      formData.append(
        "disciplines",
        this.item.disciplines ? JSON.stringify(this.item.disciplines) : []
      );
      formData.append("disable_billet", this.item.disable_billet ? 1 : 0);
      formData.append("disable_popup", this.item.disable_popup ? 1 : 0);
      formData.append(
        "disable_form_contact",
        this.item.disable_form_contact ? 1 : 0
      );
      formData.append("disable_whatsapp", this.item.disable_whatsapp ? 1 : 0);
      formData.append(
        "tags",
        this.item.tags ? JSON.stringify(this.item.tags) : ""
      );
      formData.append("status", this.item.status ? this.item.status.code : "");
      formData.append("slug", this.item.slug);
      formData.append(
        "books",
        this.item.books ? JSON.stringify(this.item.books) : []
      );

      if (!this.$v.$error) {
        this.$store
          .dispatch("Product/update", { uuid: this.uuid, data: formData })
          .then(() => {
            this.$router.push({ name: "products-presencials-list" });
            this.notifyDefault("success");
          })
          .catch(() => {
            this.submited = false;
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    destroyed() {
      window.removeEventListener("resize", this.initTrHeight);
    },
    repeateAgain() {
      this.item.disciplines.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.item.disciplines.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.listDisciplines.scrollHeight);
      });
    },
  },
  mixins: [heightTransition],
  validations: {
    item: {
      title: {
        required,
      },
      price: {
        required,
      },
      description: {
        required,
      },
      label_image: {
        required,
      },
      areas: {
        required,
      },
      datasheet: {
        required,
      },
      salesforce: {
        required: requiredIf((vm) => {
          return vm.sales_force_positions ? false : true
        })
      },
      subcategory: {
        required,
      },
      validity: {
        required,
      },
      tags: {
        required,
      },
      status: {
        required,
      },
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
};
</script>

<style>
.custom-checkbox-footer label {
  font-size: 0.9rem !important;
}
.container-uploads svg {
  width: 70px;
}
.b-form-tags.form-control {
  padding: 1rem 1rem !important;
}
.beforeUpload .icon {
  width: 50px !important;
}
.area-lessons {
  min-height: 400px;
}
</style>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
